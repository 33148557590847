import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, unref as _unref, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '../../assets/img/x.png';
import _imports_1 from '../../assets/img/dafuwong.png';
import _imports_2 from '../../assets/img/guang.png';
import _imports_3 from '../../assets/img/text.png';
import _imports_4 from '../../assets/img/arrow-bottom.png';
import _imports_5 from '../../assets/img/arrow-right.png';
import _imports_6 from '../../assets/img/a-bottom.png';
const _hoisted_1 = {
  class: "fuwong"
};
const _hoisted_2 = {
  key: 1,
  class: "button x"
};
const _hoisted_3 = {
  class: "shoyi-show"
};
const _hoisted_4 = {
  class: "show-box"
};
const _hoisted_5 = {
  class: "box-left"
};
const _hoisted_6 = {
  class: "left-number"
};
const _hoisted_7 = {
  class: "number"
};
const _hoisted_8 = {
  class: "text"
};
const _hoisted_9 = {
  class: "left-number"
};
const _hoisted_10 = {
  class: "number"
};
const _hoisted_11 = {
  class: "text"
};
const _hoisted_12 = {
  class: "box-left"
};
const _hoisted_13 = {
  class: "left-number"
};
const _hoisted_14 = {
  class: "number"
};
const _hoisted_15 = {
  class: "text"
};
const _hoisted_16 = {
  class: "left-number"
};
const _hoisted_17 = {
  class: "number"
};
const _hoisted_18 = {
  class: "text"
};
const _hoisted_19 = {
  class: "wanfa-jieshao"
};
const _hoisted_20 = ["innerHTML"];
const _hoisted_21 = {
  class: "select"
};
const _hoisted_22 = {
  key: 0
};
const _hoisted_23 = {
  key: 1
};
const _hoisted_24 = {
  class: "select-box"
};
const _hoisted_25 = ["onClick"];
const _hoisted_26 = {
  class: "select-dan-shuang"
};
const _hoisted_27 = {
  key: 1,
  class: "button"
};
const _hoisted_28 = {
  key: 2,
  class: "button x"
};
const _hoisted_29 = {
  class: "balnce"
};
const _hoisted_30 = {
  class: "jilu"
};
const _hoisted_31 = {
  key: 0
};
const _hoisted_32 = {
  class: "box"
};
const _hoisted_33 = {
  style: {
    "font-size": "12px"
  },
  class: "box-line"
};
const _hoisted_34 = {
  style: {
    "width": "70%",
    "overflow-x": "scroll"
  }
};
const _hoisted_35 = {
  key: 0
};
const _hoisted_36 = {
  key: 1
};
const _hoisted_37 = {
  style: {
    "font-size": "12px",
    "margin-bottom": "14px"
  }
};
const _hoisted_38 = {
  style: {
    "margin-right": "29px"
  }
};
const _hoisted_39 = {
  key: 0
};
const _hoisted_40 = {
  key: 1
};
const _hoisted_41 = {
  key: 2,
  style: {
    "margin-right": "17px"
  }
};
const _hoisted_42 = {
  key: 1,
  class: "nojilu"
};
import { ref, computed, onMounted, nextTick, watchEffect, watch } from 'vue';
import { user, usdt } from '@/api/index';
import { useUserStore } from '@/store/user';
import { useRouter } from 'vue-router';
import { showToast } from 'vant';
import erc20 from '@/abi/erc20.json';
import Web3 from "web3";
export default {
  __name: 'Index',
  setup(__props) {
    var web3Provider;
    web3Provider = window.ethereum;
    const web3js = new Web3(web3Provider);
    const store = useUserStore();
    console.log('ddda', store.account);
    console.log(user.firstName);
    watch(() => store.account, (newValue, oldValue) => {
      console.log("新的值:", newValue);
      console.log("旧的值:", oldValue);
      init();
    });
    const router = useRouter();
    var textType = ref(false);
    var formdan = ref(1);
    const text = ref('');
    const listloading = ref(false);
    const finished = ref(false);
    const activetext = ref('');
    const deasset = ref('');
    const token_id = ref('');
    const token_concent = ref('');
    const hashdata = ref('');
    const demoney = ref('');
    const loading = ref(false);
    const receiver = ref('');
    const showassets = ref(false);
    const showmoney = ref(false);
    const showhash = ref(false);
    const jilulist = ref([]);
    const balance = ref();
    const GDTasset = ref({});
    const VGTasset = ref({});
    const showtext = ref({});
    const page = ref(1);
    const page_size = ref(15);
    const showbutton = computed(() => {
      return !!token_id.value && !!demoney.value;
    });
    const jiaohash = async () => {};
    function inputdem() {
      if (String(demoney.value).indexOf(".") != -1) return showToast('不能是小数');
      if (parseInt(demoney.value) > 100) {
        showToast('最大输入100');
        demoney.value = 100;
        return;
      } else if (parseInt(demoney.value) < 1) {
        showToast('最小输入1');
        demoney.value = 1;
        return;
      }
      console.log(parseInt(demoney.value) % 2);
      if (parseInt(demoney.value) % 2 == 0) {
        console.log(12423);
        formdan.value = 2;
      } else {
        formdan.value = 1;
      }
    }
    // watch(formdan,(newValue, oldValue) => {
    //     demoney.value=''

    //     })
    const onLoad = () => {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        console.log(page_size.value);
        page_size.value += 10;
        page.value += 1;
        let data = {
          page: page.value,
          page_size: 15
        };
        user.hgwalleensuretrecords(data, this.account).then(res => {
          console.log(res);
          if (res.data.list.length == []) {
            finished.value = true;
          } else {
            jilulist.value = [...jilulist.value, ...res.data.list];
          }
        });

        // 加载状态结束
        listloading.value = false;

        // 数据全部加载完成
        // if (this.shoyilist.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);
    };
    const sendf = async () => {
      if (store.account == '') return showToast('请链接账号');
      if (balance.value < demoney.value) return showToast('余额不足');
      loading.value = true;
      const amount = web3js.utils.toWei(String(demoney.value), 'ether');
      usdt.transfer(store.account, receiver.value, amount, token_concent.value).then(res => {
        loading.value = false;
        getbanceof();
        showToast('交易成功');
        jiaohashbut(res);
      }).catch(e => {
        getbanceof();
        showToast('交易失败');
        loading.value = false;
      });
      // let web3 = new Web3(window.web3.currentProvider);
      // const erc20Contract = new web3.eth.Contract(erc20, token_concent.value);
      // console.log((demoney.value).toString);
      // const amount = web3.utils.toWei(String(demoney.value), 'ether')
      // console.log(amount);
      // const gas = await erc20Contract.methods.transfer(receiver.value, amount).estimateGas({ from: store.account })
      // console.log(gas);
      //  erc20Contract.methods.approve(this.ruzhangaddress ,amount).send({from:this.account})
      // try{
      //     const status = await  erc20Contract.methods.transfer(receiver.value, amount).send({ from: store.account, gas })
      //       showToast('交易成功')
      //     getbanceof()
      //     jiaohashbut(status.transactionHash)
      //     loading.value = false
      // }catch (error){
      //        showToast('交易失败')
      //     loading.value = false
      //     init()
      //     getbanceof()
      // }

      // erc20Contract.methods.transfer(receiver.value, amount).send({ from: store.account, gas }).once("receipt", (res) => {
      //     console.log(res);

      //     showToast('交易成功')
      //     getbanceof()
      //     jiaohashbut(res.transactionHash)
      //     loading.value = false
      // }).once('transactionHash', (res1) => {
      //     console.log(res1);

      // }).once('confirmation', (res2) => {
      //     // this.loading = false
      //     // console.log(res2);
      //     // this.$toast(this.$t('lang.d81'));
      //     // this.init()
      //     // this.geterc20banceof()  

      //      }).once('error', (error, receipt) => {
      //     console.log(error);
      //     showToast('交易失败')
      //     loading.value = false
      //     init()
      //     getbanceof()
      // })
      // .on("receipt", receipt => {

      //     console.log("交易成功");
      //     showToast('交易成功')
      //     getbanceof()
      //     jiaohashbut(receipt.transactionHash)
      //     loading.value = false
      // })
      // .on('error', (error, receipt) => {
      //     console.log(error);

      //     showToast('交易失败')
      //     loading.value = false
      //     init()
      //     getbanceof()
      //     throw error
      // })
    };
    const getbanceof = async () => {
      const erc20Contract = new web3js.eth.Contract(erc20, token_concent.value);
      var b = await erc20Contract.methods.balanceOf(store.account).call();
      b = web3js.utils.fromWei(b, 'ether');
      balance.value = parseFloat(b).toFixed(3);
    };
    const tibiGDt = async (id, amount) => {
      const sign = await web3js.eth.personal.sign(web3js.utils.fromUtf8('HG Withdraw'), store.account);
      let data = {
        token_id: id,
        amount: amount,
        signature: sign
      };
      user.hguserwithdraw(data, store.account).then(res => {
        showToast(res.msg);
      });
    };
    onMounted(() => {
      nextTick(() => {
        init();
      });
      setTimeout(() => {}, 50);
    });
    const init = () => {
      configs();
      getlist();
      getassetusble();
      gethginfo();
    };
    const configs = () => {
      console.log('configs.store', store.account);
      user.hgconfigs(store.account).then(res => {
        console.log(res);
        assetlist.value = res.data;
      });
    };
    const gethginfo = () => {
      user.hginfo(store.account).then(res => {
        text.value = res.data.content;
      });
    };
    const getassetusble = () => {
      user.hgwalletassets(store.account).then(res => {
        console.log(res);
        let data = res.data;
        if (data.length == 2) {
          VGTasset.value = data[1];
          GDTasset.value = data[0];
        } else {
          VGTasset.value = data[0];
        }
        // data.forEach((item,index)=>{
        //     if(item.token_name == 'VGT'){
        //           VGTasset.value =item
        //     }
        //     if(item.token_name =='GDT'){
        //          GDTasset.value =item
        //     }
        // })
        console.log(VGTasset.value);
        console.log(GDTasset.value);
      });
    };
    const getlist = () => {
      let data = {
        page: 1,
        page_size: page_size.value
      };
      user.hgwalleensuretrecords(data, this.account).then(res => {
        console.log(res);
        jilulist.value = res.data.list || [];
      });
    };
    const jiaohashbut = async dats => {
      // const sign = await web3js.eth.personal.sign(web3js.utils.fromUtf8('HG Entry Mark'), store.account)

      let data = {
        tx_hash: dats,
        signature: ''
      };
      user.walletentrymark(data, store.account).then(res => {
        showToast(res.msg);
        console.log(res.msg);
        getlist();
        getassetusble();
        if (showhash.value) {
          showhash.value = false;
        }
      });
    };
    const assetlist = ref([]);
    const moneylistdan = ref([10, 30, 50, 70, 90]);
    const moneylistshuang = ref([20, 40, 60, 80, 100]);
    const showhashfun = () => {
      showhash.value = !showhash.value;
    };
    const goGDT = () => {
      router.push('/Index');
    };
    const clicktext = () => {
      showtext.value = !showtext.value;
    };
    const showassetsfun = () => {
      showmoney.value = false;
      showassets.value = !showassets.value;
    };
    const selectasset = item => {
      showassets.value = false;
      token_id.value = item.token_id;
      token_concent.value = item.token_contract;
      deasset.value = item.token_name;
      receiver.value = item.receiver;
      if (token_concent.value != '') {
        getbanceof();
      }
    };
    const showmoneyfun = () => {
      showassets.value = false;
      showmoney.value = !showmoney.value;
    };
    const selectmoney = item => {
      showmoney.value = false;
      demoney.value = item;
    };
    return (_ctx, _cache) => {
      const _component_van_loading = _resolveComponent("van-loading");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", {
        class: "overflow-hash",
        onClick: showhashfun
      }, [_createElementVNode("div", {
        class: "hash-box",
        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
      }, [_createElementVNode("div", {
        class: "hash-title"
      }, [_cache[8] || (_cache[8] = _createElementVNode("span", null, "请输入交易哈希", -1)), _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "box-x",
        onClick: showhashfun
      })]), _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "input-hash",
        placeholder: "输入您的哈希",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => hashdata.value = $event)
      }, null, 512), [[_vModelText, hashdata.value]]), hashdata.value != '' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "button",
        onClick: _cache[1] || (_cache[1] = $event => jiaohashbut(hashdata.value))
      }, " 校验 ")) : (_openBlock(), _createElementBlock("div", _hoisted_2, "校验"))])], 512), [[_vShow, showhash.value]]), _cache[24] || (_cache[24] = _createElementVNode("img", {
        src: _imports_1,
        alt: "",
        class: "dafuwong"
      }, null, -1)), _createElementVNode("div", _hoisted_3, [_cache[12] || (_cache[12] = _createElementVNode("img", {
        src: _imports_2,
        alt: "",
        class: "show-img"
      }, null, -1)), _cache[13] || (_cache[13] = _createElementVNode("div", {
        class: "show-title"
      }, [_createElementVNode("div", null, "历史收益"), _createElementVNode("div", null, "待领取")], -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(GDTasset.value.total_amount || 0), 1), _createElementVNode("span", _hoisted_8, _toDisplayString(GDTasset.value.token_name), 1)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(VGTasset.value.total_amount || 0), 1), _createElementVNode("span", _hoisted_11, _toDisplayString(VGTasset.value.token_name), 1)])]), _cache[11] || (_cache[11] = _createElementVNode("div", {
        class: "lin"
      }, null, -1)), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(GDTasset.value.usable || 0), 1), _createElementVNode("span", _hoisted_15, _toDisplayString(GDTasset.value.token_name), 1), _createElementVNode("div", {
        class: "butt",
        onClick: _cache[3] || (_cache[3] = $event => tibiGDt(GDTasset.value.token_id, GDTasset.value.usable))
      }, _cache[9] || (_cache[9] = [_createElementVNode("span", null, "领取", -1)]))]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, _toDisplayString(VGTasset.value.usable || 0), 1), _createElementVNode("span", _hoisted_18, _toDisplayString(VGTasset.value.token_name), 1), _createElementVNode("div", {
        class: "butt",
        onClick: _cache[4] || (_cache[4] = $event => tibiGDt(VGTasset.value.token_id, VGTasset.value.usable))
      }, _cache[10] || (_cache[10] = [_createElementVNode("span", null, "领取", -1)]))])])])]), _createElementVNode("div", _hoisted_19, [_cache[14] || (_cache[14] = _createElementVNode("div", {
        class: "title-text"
      }, [_createElementVNode("img", {
        src: _imports_3,
        alt: "",
        class: "text-img"
      })], -1)), _createElementVNode("img", {
        onClick: _cache[5] || (_cache[5] = $event => clicktext()),
        src: _imports_4,
        alt: "",
        class: "arrow-bottom",
        style: _normalizeStyle(showtext.value ? 'transform: rotate(-180deg);' : '')
      }, null, 4), _createElementVNode("div", {
        innerHTML: text.value,
        class: _normalizeClass(showtext.value ? ' ac' : 'ac-div')
      }, null, 10, _hoisted_20)]), _createElementVNode("div", {
        class: "chengshishang",
        onClick: goGDT
      }, _cache[15] || (_cache[15] = [_createElementVNode("span", null, "成为做市商", -1), _createElementVNode("img", {
        src: _imports_5,
        alt: "",
        class: ""
      }, null, -1)])), _createElementVNode("div", _hoisted_21, [_createElementVNode("div", {
        class: "select-assets",
        onClick: showassetsfun
      }, [deasset.value != '' ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(deasset.value), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_23, "积分类别")), _cache[16] || (_cache[16] = _createElementVNode("img", {
        src: _imports_6,
        alt: ""
      }, null, -1)), _withDirectives(_createElementVNode("div", _hoisted_24, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetlist.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "",
          onClick: _withModifiers($event => selectasset(item), ["stop"])
        }, _toDisplayString(item.token_name), 9, _hoisted_25);
      }), 256))], 512), [[_vShow, showassets.value]])]), _createElementVNode("div", _hoisted_26, [_cache[17] || (_cache[17] = _createElementVNode("span", null, "输入积分数量", -1)), _createElementVNode("div", {
        class: _normalizeClass(_unref(formdan) == 1 ? 'box-active box' : 'box')
      }, "单", 2), _createElementVNode("div", {
        class: _normalizeClass(_unref(formdan) == 2 ? 'box-active box' : 'box')
      }, "双", 2)]), _createElementVNode("div", {
        class: "select-assets",
        onClick: showmoneyfun
      }, [_withDirectives(_createElementVNode("input", {
        type: "number",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => demoney.value = $event),
        onInput: inputdem,
        placeholder: "输入积分数量"
      }, null, 544), [[_vModelText, demoney.value]])]), showbutton.value && loading.value == false ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "button",
        onClick: sendf
      }, _cache[18] || (_cache[18] = [_createElementVNode("span", null, "确认", -1)]))) : loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_27, [loading.value ? (_openBlock(), _createBlock(_component_van_loading, {
        key: 0,
        type: "spinner",
        color: "#313235"
      })) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_28, "确认")), _createElementVNode("div", _hoisted_29, [_cache[19] || (_cache[19] = _createElementVNode("span", null, "余额", -1)), _createElementVNode("span", null, _toDisplayString(balance.value), 1)])]), _createElementVNode("div", _hoisted_30, [_createElementVNode("div", {
        class: "jilu-title"
      }, [_cache[20] || (_cache[20] = _createElementVNode("span", null, "我的记录", -1)), _createElementVNode("div", {
        class: "xiaoyan",
        onClick: showhashfun
      }, "校验")]), jilulist.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_31, [_createVNode(_component_van_list, {
        class: "boxs",
        modelValue: listloading.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => listloading.value = $event),
        finished: finished.value,
        "finished-text": "没有更多了",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(jilulist.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", _hoisted_32, [_cache[23] || (_cache[23] = _createElementVNode("div", {
            class: "box-line",
            style: {
              "margin-bottom": "14px"
            }
          }, [_createElementVNode("span", null, "竞猜"), _createElementVNode("span", null, "结果")], -1)), _createElementVNode("div", _hoisted_33, [_createElementVNode("div", _hoisted_34, [_createElementVNode("span", null, _toDisplayString(item.tx_hash), 1)]), item.win_reward > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_35, "+" + _toDisplayString(item.win_reward), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_36, "未中奖"))]), _createElementVNode("div", _hoisted_37, [_createElementVNode("span", _hoisted_38, "币种：" + _toDisplayString(item.token_name), 1), _createElementVNode("span", null, "数量：" + _toDisplayString(item.ensure_amount), 1)]), _createElementVNode("div", null, [_cache[21] || (_cache[21] = _createTextVNode(" 特殊奖励： ")), item.leopard_reward > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(item.leopard_reward), 1)) : item.continuity_reward > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(item.continuity_reward), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_41, "无")), _createElementVNode("span", null, "尾数：" + _toDisplayString(item.last_number), 1)]), _createElementVNode("div", null, [_cache[22] || (_cache[22] = _createElementVNode("span", null, "竞猜时间：", -1)), _createElementVNode("span", null, _toDisplayString(item.created_time), 1)])]);
        }), 256))]),
        _: 1
      }, 8, ["modelValue", "finished"])])) : (_openBlock(), _createElementBlock("div", _hoisted_42, "暂无记录 ~"))])]);
    };
  }
};