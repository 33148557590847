import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "Index"
};
const _hoisted_2 = {
  class: "select"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  style: {
    "width": "100%"
  }
};
import GDTindex from './component/GDTindex.vue';
import VGTindex from './component/VGTindex.vue';
import { ref, onMounted, getCurrentInstance, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { zhoncho } from '@/api/index';
export default {
  __name: 'Index',
  setup(__props) {
    const store = useUserStore();
    const router = useRouter();
    var GDTdata = {};
    var VGTdata = {};
    const index = ref(1);
    onMounted(() => {
      init();
    });
    const init = () => {
      getcfconfigs();
      switchindex();
    };
    watch(() => store.account, (newValue, oldValue) => {
      console.log("新的值:", newValue);
      console.log("旧的值:", oldValue);
      init();
    });
    const switchlist = ref([]);
    const switchindex = (data = '') => {
      if (data != '') {
        index.value = data;
      }
    };
    const getcfconfigs = () => {
      zhoncho.cfconfigs(store.account).then(res => {
        let data = res.data;
        for (let i in data) {
          data[i].index = parseInt(i) + 1;
        }
        switchlist.value = data;
        GDTdata = data[0];
        VGTdata = data[1];
        console.log(data);
        console.log('switchlist.value', switchlist.value);
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(switchlist.value, item => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(index.value == item.index ? 'box active' : 'box '),
          onClick: $event => switchindex(item.index)
        }, _toDisplayString(item.token_name), 11, _hoisted_3);
      }), 256))]), _createElementVNode("div", _hoisted_4, [index.value == 1 ? (_openBlock(), _createBlock(GDTindex, {
        key: 0,
        configdata: _unref(GDTdata)
      }, null, 8, ["configdata"])) : index.value == 2 ? (_openBlock(), _createBlock(GDTindex, {
        key: 1,
        configdata: _unref(VGTdata)
      }, null, 8, ["configdata"])) : _createCommentVNode("", true)])]);
    };
  }
};