import request from '../utils/request';
export default {
      //资产
      cfwalletassets(account) {
        
        return request.request({
            url: '/cf/wallet/assets',
            method: "get",
            headers:{
                Authorization:account
             },
        
        })
    },
      //资产账单
      cfwalletrecords(data,account) {
        
        return request.request({
            url: '/cf/wallet/records',
            method: "get",
            headers:{
                Authorization:account
             },
        params:data
        })
    },
     //配置
     cfconfigs(account) {
        
        return request.request({
            url: '/cf/configs',
            method: "get",
            headers:{
                Authorization:account
             },
       
        })
    },
    //提币
    cfuserwithdraw(data,account) {
        
        return request.request({
            url: '/cf/user/withdraw',
            method: "post",
            headers:{
                Authorization:account
             },
       data:data
        })
    },
      //标记补录入账
      cfuserentrymark(data,account) {
        
        return request.request({
            url: '/cf/user/entry-mark',
            method: "post",
            headers:{
                Authorization:account
             },
       data:data
        })
    },
    //池子信息
     
     cfpoollist(account) {
        
        return request.request({
            url: '/cf/pool/list',
            method: "get",
            headers:{
                Authorization:account
             },
       
        })
    },
}