import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main"
};
import Header from './components/Header.vue';
import { ref } from 'vue';
import { onMounted, nextTick, onBeforeMount } from 'vue';
import { useUserStore } from '@/store/user';
import { user } from '@/api/index';
import Web3 from "web3";
const bian = "https://bscscan.com/";
export default {
  __name: 'App',
  setup(__props) {
    const store = useUserStore();
    const account = ref('');
    onBeforeMount(async () => {
      store.setWebProvider();
      console.log('store', store.account);
      // await getaccount()
      if (!localStorage.getItem('Lang')) {
        localStorage.setItem('Lang', 'zh-CN');
      }
      sessionStorage.setItem('url', bian);
      setInterval(() => {
        store.setWebProvider();
      }, 6000);
    });
    const getaccount = async () => {
      web3.eth.requestAccounts().then(res => {
        let account1 = account.value;
        account.value = res[0];
        console.log(account.value);
        if (account1 != account.value) {
          let data = {
            address: account.value
          };
          user.register(data).then(res => {
            console.log(res);
          });
        }
        if (account1 != account.value || store.account == '' || store.account == undefined) {
          store.account = account.value;
          console.log('222222222', store.account);
        }
      });
      const aaa = await web3.eth.getGasPrice();
      console.log(aaa);
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(Header, {
        style: {
          "z-index": "99999"
        },
        account: account.value
      }, null, 8, ["account"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])], 64);
    };
  }
};