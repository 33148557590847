import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/img/x.png';
const _hoisted_1 = {
  class: "GDT"
};
const _hoisted_2 = {
  key: 1,
  class: "button x"
};
const _hoisted_3 = {
  class: "echart-box"
};
const _hoisted_4 = {
  class: "echart-text"
};
const _hoisted_5 = {
  key: 1,
  class: "canyu"
};
const _hoisted_6 = {
  class: "lingqun"
};
const _hoisted_7 = {
  class: "bottom"
};
const _hoisted_8 = {
  class: "number"
};
const _hoisted_9 = {
  class: "zhangdan"
};
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  class: "zhangdan-li"
};
const _hoisted_12 = {
  class: "title"
};
const _hoisted_13 = {
  class: "title-left"
};
const _hoisted_14 = {
  class: "title-right"
};
const _hoisted_15 = {
  class: "time"
};
const _hoisted_16 = {
  key: 1,
  class: "nojilu"
};
import { onMounted, ref, nextTick, onBeforeUnmount, watch } from 'vue';
import * as echarts from 'echarts';
import { zhoncho, usdt } from '@/api/index';
import { useUserStore } from '@/store/user';
import { showToast } from 'vant';
import erc20 from '@/abi/erc20.json';
import Web3 from "web3";
export default {
  __name: 'GDTindex',
  props: {
    configdata: {
      type: Object
    }
  },
  setup(__props) {
    const store = useUserStore();
    const showhash = ref(false);
    const loading = ref(false);
    const hashdata = ref('');
    var web3Provider;
    web3Provider = window.ethereum;
    const web3js = new Web3(web3Provider);
    var GDTdata = {};
    const listloading = ref(false);
    const finished = ref(false);
    const GDTasset = ref({});
    const GDTechatdata = ref({});
    var jilulist = ref([]);
    var chartDom;
    var myChart;
    const balance = ref(0);
    const page_size = ref(15);
    const page = ref(1);
    const props = __props;
    watch(() => store.account, (newValue, oldValue) => {
      console.log("新的值:", newValue);
      console.log("旧的值:", oldValue);
      init();
    });
    watch(() => props.configdata, newProps => {
      console.log('ffffffff', newProps); //查看新值
      GDTdata = props.configdata;
      init();
    });
    onMounted(() => {
      console.log('props.configdata', props.configdata);
      GDTdata = props.configdata;
      init();
      console.log('GDTdata', GDTdata.token_id);
    });
    const init = async () => {
      getlist();
      getassets();
      getcfpoollist();
      getbanceof();
    };
    const getcfpoollist = () => {
      zhoncho.cfpoollist(store.account).then(res => {
        let data = res.data;
        for (let i in data) {
          if (props.configdata.token_id == data[i].token_id) {
            GDTechatdata.value = data[i];
          }
        }
        echartfun();
      });
    };
    const getlist = () => {
      let data = {
        page: 1,
        page_size: page_size.value,
        behavior: 4,
        token_id: props.configdata.token_id
      };
      zhoncho.cfwalletrecords(data, store.account).then(res => {
        console.log(res);
        jilulist.value = res.data.list;
      });
    };
    const onLoad = () => {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      let timer = setTimeout(() => {
        console.log(page_size.value);
        page.value += 1;
        let data = {
          page: page.value,
          page_size: page_size.value,
          behavior: 4,
          token_id: props.configdata.token_id
        };
        zhoncho.cfwalletrecords(data, store.account).then(res => {
          if (res.data.list.length == []) {
            finished.value = true;
          } else {
            jilulist.value = [...jilulist.value, ...res.data.list];
          }
        });

        // 加载状态结束
        listloading.value = false;

        // 数据全部加载完成
        // if (this.shoyilist.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);
    };
    const linqun = async () => {
      const sign = await web3js.eth.personal.sign(web3js.utils.fromUtf8('CF Withdraw'), store.account);
      let data = {
        token_id: GDTasset.value.token_id,
        amount: GDTasset.value.usable,
        signature: sign
      };
      zhoncho.cfuserwithdraw(data, store.account).then(res => {
        showToast(res.msg);
        init();
      });
    };
    const getassets = () => {
      zhoncho.cfwalletassets(store.account).then(res => {
        let data = res.data;
        for (let i in data) {
          if (props.configdata.token_id == data[i].token_id) {
            GDTasset.value = data[i];
          }
        }
        console.log(GDTasset.value);
      });
    };
    const sendf = async () => {
      console.log('GDTdata.receiver', GDTdata.receiver);
      if (store.account == '') return showToast('请链接账号');

      //     let profit = GDTechatdata.value.profit_amount + parseFloat(hashdata.value)
      // console.log('profit',profit);
      //     if(profit >= GDTechatdata.value.total_amount) return showToast('今日投注额应小于奖金池总额')
      console.log('balance.value', balance.value);
      if (parseFloat(balance.value) < hashdata.value) return showToast('余额不足');
      showhash.value = false;
      loading.value = true;
      const amount = web3js.utils.toWei(String(hashdata.value), 'ether');
      usdt.transfer(store.account, GDTdata.receiver, amount, GDTdata.token_contract).then(res => {
        loading.value = false;
        showToast('交易成功');
        getbanceof();
        jiaohashbut(res);
      }).catch(e => {
        showToast('交易失败');
        getbanceof();
        loading.value = false;
      });
      // let web3 = new Web3(window.web3.currentProvider);
      // const erc20Contract = new web3.eth.Contract(erc20, GDTdata.receiver);
      // console.log((hashdata.value).toString);
      // const amount = web3.utils.toWei(String(hashdata.value), 'ether')
      // console.log(amount);
      // const gas = await erc20Contract.methods.transfer(GDTdata.receiver, amount).estimateGas({ from: store.account })
      // console.log(gas);
      //  erc20Contract.methods.approve(this.ruzhangaddress ,amount).send({from:this.account})
      // try {

      //     const status = await erc20Contract.methods.transfer(GDTdata.receiver, amount).send({ from: store.account, gas })
      //     console.log('status',status);
      //     showToast('交易成功')
      //     getbanceof()
      //     jiaohashbut(status.transactionHash)
      //     loading.value = false
      // }catch (error){
      //     showToast('交易失败')
      //     loading.value = false

      //     getbanceof()
      // }
      // erc20Contract.methods.transfer(GDTdata.receiver, amount).send({ from: store.account, gas }).once("receipt", (res) => {
      //     showToast('交易成功')
      //     getbanceof()
      //     jiaohashbut(receipt.transactionHash)
      //     loading.value = false
      // }).once('transactionHash', (res1) => {
      //     console.log(res1);

      // }).once('confirmation', (res2) => {
      // this.loading = false
      // console.log(res2);
      // this.$toast(this.$t('lang.d81'));
      // this.init()
      // this.geterc20banceof()  

      // }).once('error', (error, receipt) => {
      //     console.log(error);
      //     showToast('交易失败')
      //     loading.value = false
      //     getbanceof()
      //     throw error
      // })
      // .on("receipt", receipt => {
      //     showToast('交易成功')
      //     getbanceof()
      //     jiaohashbut(receipt.transactionHash)
      //     loading.value = false
      // }).on('error', (error, receipt) => {
      //     console.log(error);
      //     showToast('交易失败')
      //     loading.value = false
      //     getbanceof()
      //     throw error
      // })
    };
    const jiaohashbut = async dats => {
      const sign = await web3js.eth.personal.sign(web3js.utils.fromUtf8('CF Entry Mark'), store.account);
      let data = {
        tx_hash: dats,
        signature: sign
      };
      zhoncho.cfuserentrymark(data, store.account).then(res => {
        showToast(res.msg);
        console.log(res.msg);
        init();
      });
    };
    const getbanceof = async () => {
      const erc20Contract = new web3js.eth.Contract(erc20, props.configdata.token_contract);
      var b = await erc20Contract.methods.balanceOf(store.account).call();
      b = web3js.utils.fromWei(b, 'ether');
      balance.value = parseFloat(b).toFixed(3);
    };
    const showhashfun = () => {
      showhash.value = !showhash.value;
    };
    const echartfun = () => {
      let data = [{
        value: GDTechatdata.value.total_amount,
        name: '总众筹'
      }, {
        value: GDTechatdata.value.join_amount,
        name: '个人参与额'
      }];
      console.log('echart', data);
      chartDom = document.getElementById('echat');
      chartDom.removeAttribute('_echarts_instance_');
      myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'item'
        },
        series: [{
          name: '',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 0,
            borderColor: '#fff',
            borderWidth: 0
          },
          label: {
            show: false,
            position: 'center'
          },
          //   emphasis: {
          //     label: {
          //       show: true,
          //       fontSize: 40,
          //       fontWeight: 'bold'
          //     }
          //   },
          labelLine: {
            show: false
          },
          data: data
        }]
      };
      option && myChart.setOption(option);
    };
    return (_ctx, _cache) => {
      const _component_van_loading = _resolveComponent("van-loading");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", {
        class: "overflow-hash",
        onClick: showhashfun
      }, [_createElementVNode("div", {
        class: "hash-box",
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
      }, [_createElementVNode("div", {
        class: "hash-title"
      }, [_cache[3] || (_cache[3] = _createElementVNode("span", null, "请输入金额", -1)), _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "box-x",
        onClick: showhashfun
      })]), _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "input-hash",
        placeholder: "输入金额",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => hashdata.value = $event)
      }, null, 512), [[_vModelText, hashdata.value]]), hashdata.value != '' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "button",
        onClick: sendf
      }, " 参与 ")) : (_openBlock(), _createElementBlock("div", _hoisted_2, "参与"))])], 512), [[_vShow, showhash.value]]), _createElementVNode("div", _hoisted_3, [_cache[4] || (_cache[4] = _createElementVNode("span", {
        class: "echart-title"
      }, "参与股东", -1)), _createElementVNode("span", _hoisted_4, "当前奖金池数量：" + _toDisplayString(GDTechatdata.value.total_amount), 1), _cache[5] || (_cache[5] = _createElementVNode("div", {
        id: "echat",
        style: {
          "width": "100%",
          "height": "260px"
        }
      }, null, -1)), !loading.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "canyu",
        onClick: showhashfun
      }, " 参与")) : (_openBlock(), _createElementBlock("div", _hoisted_5, [loading.value ? (_openBlock(), _createBlock(_component_van_loading, {
        key: 0,
        type: "spinner",
        color: "#313235"
      })) : _createCommentVNode("", true)]))]), _createElementVNode("div", _hoisted_6, [_cache[6] || (_cache[6] = _createElementVNode("span", null, "领取分红", -1)), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(GDTasset.value.usable), 1), _createElementVNode("div", {
        class: "button",
        onClick: linqun
      }, "领取")])]), _createElementVNode("div", _hoisted_9, [_cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "zhangdan-title"
      }, "分红账单", -1)), _unref(jilulist).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_van_list, {
        class: "list",
        modelValue: listloading.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => listloading.value = $event),
        finished: finished.value,
        "finished-text": "没有更多了",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(jilulist), (item, index) => {
          return _openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("span", _hoisted_13, _toDisplayString(item.token_name) + _toDisplayString(item.behavior_txt), 1), _createElementVNode("span", _hoisted_14, "＋" + _toDisplayString(item.usable_change) + _toDisplayString(item.token_name), 1)]), _createElementVNode("div", _hoisted_15, [_createElementVNode("span", null, _toDisplayString(item.created_time), 1)])]);
        }), 256))]),
        _: 1
      }, 8, ["modelValue", "finished"])])) : (_openBlock(), _createElementBlock("div", _hoisted_16, "暂无记录 ~"))])]);
    };
  }
};