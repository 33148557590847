import request from '../utils/request';
export default {
    //注册用户
    register(data,account) {
        
        return request.request({
            url: '/register',
            method: "post",
            headers:{
                Authorization:account
             },
            data:data
        })
    },
    hgconfigs(account) {
        console.log('hgconfigsaccount',account);
        return request.request({
            url: '/hg/configs',
            method: "get",
            headers:{
                Authorization:account
             },
           
        })
    },
    walletentrymark(data,account) {
        
        return request.request({
            url: '/hg/user/entry-mark',
            method: "post",
            headers:{
                Authorization:account
             },
            data:data
        })
    },
    hgwalleensuretrecords(data,account) {
        
        return request.request({
            url: '/hg/wallet/ensure/records',
            method: "get",
            headers:{
                Authorization:account
             },
            params:data
        })
    },
    hgwalletassets(account) {
        
        return request.request({
            url: '/hg/wallet/assets',
            method: "get",
            headers:{
                Authorization:account
             },
          
        })
    },
    hginfo(account) {
        
        return request.request({
            url: '/hg/info',
            method: "get",
            headers:{
                Authorization:account
             },
          
        })
    },
    hguserwithdraw(data,account) {
        
        return request.request({
            url: '/hg/user/withdraw',
            method: "post",
            headers:{
                Authorization:account
             },
            data:data
        })
    },
}