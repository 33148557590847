import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue";
import _imports_0 from '../assets/logo.png';
import _imports_1 from '../assets/img/wangluo.png';
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "lang"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "h_right"
};
import { reactive, nextTick } from 'vue';
import { ref, computed, onMounted } from "vue";
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'Header',
  props: {
    account: {
      type: String
    }
  },
  setup(__props) {
    const router = useRouter();
    const store = useUserStore();
    const {
      locale: i18nLanguage
    } = useI18n();
    const toggle = (languageKey, langtext) => {
      showlang.value = false;
      localStorage.setItem('Lang', languageKey);
      currentLanguage.value = langtext;
      i18nLanguage.value = languageKey; // zh en
    };
    const sholangfun = () => {
      showlang.value = false;
    };
    const currentLanguage = ref("中文简体");
    const showlang = ref(false);
    onMounted(() => {
      actions.forEach((item, index) => {
        console.log(11);
      });
    });
    const goIndex = () => {
      router.push('/');
    };
    const props = __props;
    const handleLogin = () => {
      store.setWebProvider();
      window.location.reload();
    };
    const fromaddress = ref('');
    var actions = [{
      text: '中文简体',
      lang: 'zh-CN'
    }, {
      text: 'English',
      lang: 'en-US'
    }, {
      text: '中文繁体',
      lang: 'zh-HK'
    }];
    const fromcom = computed(() => {
      const head = store.account.slice(0, 6);
      const tail = store.account.slice(store.account.length - 4);
      if (store.account == '') {
        return '请连接账号';
      } else {
        return head + "..." + tail;
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", {
        class: "fixed",
        onClick: _cache[0] || (_cache[0] = $event => sholangfun())
      }, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(actions), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          onClick: _withModifiers($event => toggle(item.lang, item.text), ["stop"])
        }, _toDisplayString(item.text), 9, _hoisted_3);
      }), 256))])], 512), [[_vShow, showlang.value]]), _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "logo",
        onClick: goIndex
      }), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        onClick: handleLogin
      }, _toDisplayString(fromcom.value), 1), _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = $event => showlang.value = !showlang.value)
      }, [_cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)), _createTextVNode(" " + _toDisplayString(currentLanguage.value), 1)])])]);
    };
  }
};