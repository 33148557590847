import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/zuoshang/Index.vue'

import fuwongIndex from '../views/fuwong/Index.vue'
const routes = [
  {
      path:'/',
   
      name: 'fuwongIndex',
      component: fuwongIndex,
  },
  {
    path: '/Index',
   
    name: 'Index',
    component: Index,
    children:[
       
     
    
  ]
  },
 
]

const router = createRouter({
  mode:'hash',
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
